<template>
  <div class="mt-6 lg:mt-0 mx-auto lg:pl-3 lg:w-4/5 productLeft">
    <div
      class="
        grid grid-cols-1
        gap-8
        mt-8
        md:grid-cols-2
        lg:grid-cols-3
        xl:grid-cols-4
      "
    >
        <div
    class="
      flex flex-col
      items-center
      sm:px-0
      px-4
      justify-center
      w-full
      max-w-lg
      mx-auto
    "
  >
    <img
      class="object-cover w-full rounded-md h-72 xl:h-80"
      src="img/banyo1.jpg"
      alt="T-Shirt"
    />
  </div>
    <div
    class="
      flex flex-col
      items-center
      sm:px-0
      px-4
      justify-center
      w-full
      max-w-lg
      mx-auto
    "
  >
    <img
      class="object-cover w-full rounded-md h-72 xl:h-80"
      src="img/giyinme2.jpg"
      alt="T-Shirt"
    />

  </div>
    <div
    class="
      flex flex-col
      items-center
      sm:px-0
      px-4
      justify-center
      w-full
      max-w-lg
      mx-auto
    "
  >
    <img
      class="object-cover w-full rounded-md h-72 xl:h-80"
      src="img/mutfak9.jpg"
      alt="T-Shirt"
    />

  </div>
    <div
    class="
      flex flex-col
      items-center
      sm:px-0
      px-4
      justify-center
      w-full
      max-w-lg
      mx-auto
    "
  >
    <img
      class="object-cover w-full rounded-md h-72 xl:h-80"
      src="img/mutfak7.jpg"
      alt="T-Shirt"
    />
  </div>
      <div
    class="
      flex flex-col
      items-center
      sm:px-0
      px-4
      justify-center
      w-full
      max-w-lg
      mx-auto
    "
  >
    <img
      class="object-cover w-full rounded-md h-72 xl:h-80"
      src="img/mutfak1.jpg"
      alt="T-Shirt"
    />
  </div>
      <div
    class="
      flex flex-col
      items-center
      sm:px-0
      px-4
      justify-center
      w-full
      max-w-lg
      mx-auto
    "
  >
    <img
      class="object-cover w-full rounded-md h-72 xl:h-80"
      src="img/tv3.jpg"
      alt="T-Shirt"
    />
  </div>

    </div>
  </div>
</template>

<script>

export default {
  name: "ProductLeft",
  components: {

  },
};
</script>
